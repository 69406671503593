import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './pages/Home';     // Importing Home component
import About from './pages/About';   // Importing About component
import Contact from './pages/Contact'; // Importing Contact component
import Blog from './pages/Blog';
import Tool from './pages/Tool';
import Footer from './components/footer';
import Header from './components/header';
import BlogTemplate from './pages/Blogs/Template';
import HighConvertingWebsiteCan10xYourBusiness from './pages/Blogs/high-converting-website-can-10x-your-business';
import WebsiteDesignGrader from './pages/Tools/WebsiteDesignGrader';
import SEOAudit from './pages/Tools/SEOAudit';
import Pricing from './pages/Pricing';
import SeoSimplified from './pages/Blogs/seo-simplified';
import SteveJobsDesignPhilosophy from './pages/Blogs/steve-jobs-design-philosophy';
import ScrollToTop from './components/scrolltotop';
import SeoForDevelopers from './pages/Blogs/seo-for-developers';
import Buy from './pages/Buy';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';
import WebsiteDesignTerms from './pages/Blogs/website-design-terms';
import AhrefBacklinkChecker from './pages/Tools/AhrefBacklinkChecker';
import Confirmation from './pages/Confirmation';
import CollectionCount from './pages/CollectionCount';
import EssentialsForAWebsite from './pages/Blogs/7-essentials-for-a-website';
import ReactUILibraries from './pages/Blogs/react-ui-libraries';
import WebsiteEvolution from './pages/Blogs/website-evolution';


function App() {
  return (
    <Router>
      <div>
        <Header></Header>
        <ScrollToTop></ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/template" element={<BlogTemplate />} />
          <Route path="/blog/high-converting-website-can-10x-your-business" element={<HighConvertingWebsiteCan10xYourBusiness />} />
          <Route path="/blog/seo-simplified" element={<SeoSimplified />} />
          <Route path="/blog/steve-jobs-design-philosophy" element={<SteveJobsDesignPhilosophy />} />
          <Route path="/blog/seo-for-developers" element={<SeoForDevelopers />} />
          <Route path="/blog/website-design-terms" element={<WebsiteDesignTerms />} />
          <Route path="/blog/7-essentials-for-a-website" element={<EssentialsForAWebsite />} />
          <Route path="/blog/react-ui-libraries" element={<ReactUILibraries />} />
          <Route path="/blog/website-evolution" element={<WebsiteEvolution />} />





          <Route path="/pricing" element={<Pricing />} />
          <Route path="/results" element={<Home />} />
          <Route path="/buy" element={<Buy/>} />
          <Route path="/confirmation" element={<Confirmation/>} />


          
          {/* <Route path="/tools" element={<Tool />} />
          <Route path="/tools/websitegrader" element={<WebsiteDesignGrader />} />
          <Route path="/tools/seoaudit" element={<SEOAudit />} />
          <Route path="/tools/ahrefbacklinkchecker" element={<AhrefBacklinkChecker />} /> */}





          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/admin" element={<CollectionCount/>} />


          <Route path="/*" element={<Home />} />

        </Routes>
        <div>
          <Footer></Footer>
        </div>
      </div>
    </Router>
  );
}

export default App;
