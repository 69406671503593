import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../styles.css';

function Header() {
  const location = useLocation(); // Get the current path
  const [menuOpen, setMenuOpen] = useState(false); // State to toggle menu

  // Toggle the mobile menu
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Define the URL where you want the Header to be hidden
  const hiddenPath = "/buy"; 

  // If the current path matches the hiddenPath, return null (which hides the component)
  if (location.pathname === hiddenPath) {
    return null;
  }

  return (
    <header className='header'>
      <Link onClick={() => setMenuOpen(false)} to="/" className='headerlogo'>Ȼalucid</Link>
      
      {/* Hamburger icon for mobile */}
      <button className='hamburger' onClick={toggleMenu}>
        {menuOpen ? 'X' : '☰'}
      </button>
      
      <nav className={menuOpen ? 'menu open' : 'menu'}>
        <ul>
          <li>
            <Link to="/" className={location.pathname === "/" ? "active" : ""} onClick={() => setMenuOpen(false)}>Home</Link>
          </li>
          <li>
            <Link to="/pricing" className={location.pathname === "/pricing" ? "active" : ""} onClick={() => setMenuOpen(false)}>Pricing</Link>
          </li>
          <li>
            <Link to="/blog" className={location.pathname === "/blog" ? "active" : ""} onClick={() => setMenuOpen(false)}>Blog</Link>
          </li>
          {/* <li>
            <Link to="/tools" className={location.pathname === "/tools" ? "active" : ""} onClick={() => setMenuOpen(false)}>Tools</Link>
          </li> */}
          <li>
            <Link to="/contact" className={location.pathname === "/contact" ? "active" : ""} onClick={() => setMenuOpen(false)}>Contact</Link>
          </li>
          <li>
            <Link to="/about" className={location.pathname === "/about" ? "active" : ""} onClick={() => setMenuOpen(false)}>About</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
