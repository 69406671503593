import React from 'react';
import '../styles.css';
import { Link } from 'react-router-dom';


function Footer(){
  return (
    <div className='footer'>
        <div className='footerseperate'>
          <Link className='footerlogo' to="/"><div >Calucid</div></Link>
            <div className='footersection'>
              <Link className='footerlink' to="/">Home</Link>
              {/* <Link className='footerlink' to="/results">Results</Link> */}
              <Link className='footerlink' to="/pricing">Pricing</Link>
              <Link className='footerlink' to="/blog">Blog</Link>
              <Link className='footerlink' to="/contact">Contact</Link>
              <Link className='footerlink' to="/about">About</Link>
            </div>
        </div>
        <div className='footersection'>
          <Link className='footerlink' to="/privacypolicy">Privacy</Link>
          <Link className='footerlink' to="/terms">Terms</Link>
        </div>
        <div className='footerseperate'>
            <div style={{margin:'10px'}}>© 2024, by Calucid.com</div>
            <div>
              <a className='footerlink' href='https://x.com/calucidofficial'>Twitter</a>
              <a className='footerlink' href='https://instagram.com/calucidofficial'>Instagram</a>
            </div>
        </div>
    </div>
  );
}

export default Footer;